<template>
  <div class="search">
    <div id="search-box">
      <div class="serch-icon">
        <svg width="32px" height="32px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 11.1455C5.49956 8.21437 7.56975 5.69108 10.4445 5.11883C13.3193 4.54659 16.198 6.08477 17.32 8.79267C18.4421 11.5006 17.495 14.624 15.058 16.2528C12.621 17.8815 9.37287 17.562 7.3 15.4895C6.14763 14.3376 5.50014 12.775 5.5 11.1455Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.989 15.4905L19.5 19.0015" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <input type="text" class="serch-input" v-model="query" placeholder="Movies, shows and more" @input="debouncedSearch"/>
      <div class="clear-btn" @click="clearSearch">
        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" fill="none"/>
          <path d="M7 17L16.8995 7.10051" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 7.00001L16.8995 16.8995" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div v-if="categories && dataFetched && searchDatabase.length > 0" class="category-nav">
      <ul class="list-items">
        <li v-for="(category, index) in categories" :key="index" 
        :class="selectedCategory == category
        ? 'active'
        : ''" 
        @click="selectCategory(category)"
        class="menu-list-item">
        <p style="display: flex; gap: 0.4rem;">
          <span class="itemTitle">{{ category }}</span>
        </p> 
        </li>
      </ul>
    </div>
    <div v-if="dataFetched" class="search-container searched-contents-container">
      <div v-if="Array.isArray(searchDatabase) && searchDatabase.length > 0" id="showingResults" class="result-box">
        <div class="livetv-container inner-container" v-if="searchDatabase.length > 0 && selectedCategory== 'ALL' && this.query">
          <Grid :contents="searchDatabase" :playerInstance="playerInstance" :isScrollable="false"></Grid>
        </div>        
        <div class="livetv-container inner-container" v-if="livetvDatabase.length > 0 && selectedCategory== 'TVCHANEL'">
          <!-- <h2 class="search-header-code" style="font-size:19px;">{{$t("Live TV")}}</h2> -->
          <Grid :contents="livetvDatabase" :playerInstance="playerInstance" :isScrollable="false"></Grid>
        </div>
        <div class="movies-container inner-container" v-if="moviesDatabase.length > 0 && selectedCategory== 'MOVIES'">
          <!-- <h2 class="search-header-code" style="font-size:19px;">{{$t("Movies")}}</h2> -->
          <Grid :contents="moviesDatabase" :playerInstance="playerInstance" :isScrollable="false"></Grid>
        </div>
        <div class="tvshows-container inner-container" v-if="tvshowsDatabase.length > 0 && selectedCategory== 'TVSHOWS'">
          <!-- <h2 class="search-header-code" style="font-size:19px;">{{$t("Tv Shows")}}</h2> -->
          <Grid :contents="tvshowsDatabase" :playerInstance="playerInstance" :isScrollable="false"></Grid>
        </div>
      </div>
      <div v-else-if="Array.isArray(searchDatabase) && searchDatabase.length == 0 && this.query == ''" id="popularSearchData" class="result-box">
        <div class="inner-container">
          <h2 class="search-header-code" style="font-size:19px;">{{$t("Popular Searches")}}</h2>
          <Grid :contents="popularSearch" :playerInstance="playerInstance" :isScrollable="isScrollable"></Grid>
        </div>
      </div> 
      <div v-else id="surchResultEmpty" class="EmptyContainer">
        <EptyBin></EptyBin>
        <h2 class="empty-header">{{$t("Nothing found!")}}</h2>
        <p>{{$t("Try searching for something else")}}</p>
      </div>
    </div>
    <div v-if="!dataFetched" class="search-container">
      <GridLayoutPlaceholder></GridLayoutPlaceholder>
    </div>
    <div id="IntersectContainer" tyle="opacity: 0; height: 20px; width:100%;">
      <Loading class="loader" :size="'20px'" v-if="showLoader && dataFetched && searchDatabase.length > 0"></Loading>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex"
import  { actGetSearchData, actGetPopulerList } from '@/modules/search';
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      routeSearchQuery: null,
      movieObj: null,
      searchedData: null,
      searchedSeriesData: null,
      searchedMoviesData: null,
      categorizedSeries: null,
      categorizedMovies: null,
      categorizedContents: null,
			mainSearchedContents: [],
      searchedContents: [],
      searchDatabase: null,
      popularSearch: [],
			dataFetched: false,
      isHeaderVisible: true,
      totalCount: 0,
      isScrollable: false,
      query: "",
      localDisplayLang: "",
      moviesDatabase: [],
      tvshowsDatabase: [],
      livetvDatabase: [],
      categories: [],
      selectedCategory: 'ALL',
      pageNum: 1,
      showLoader: false
    };
  },
  computed: {
    ...mapGetters(["getSearchdata"]),
    fetchCardType() {
      if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "100%",
          width: "100%",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      }  else if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "100%",
          width: "100%",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      } else {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "100%",
          width: "100%",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      }
      // return {
      //     cardName: "Trailer",
      //     type: "LANDSCAPE",
      //     height: "93px",
      //     width: "165px",
      //     quality: "THUMBNAIL",
      //     playButton: { enablePlay: true, height: "40px", width: "40px" }
      // }
    },
  },
  created() {
    this.debouncedSearch = this.debounce(this.debouncedSearch, 800);

    this.routeSearchQuery = this.$route.params.query;
    if(this.$route?.query?.q){
      this.query = this.$route?.query?.q;
      this.searchDatabase = [];
      this.searchApi(this.query);
    }
    
    let payload = {
        endpoint: "subscriber/v1/content",
        params: {
            category: "",
            genre: "",
            language: "",
            objecttype: "CONTENT",
            searchtype: "STRICT",
            subcategory: "",
            tags: "",
            listtype:" POPULAR"
        }
    }

    actGetPopulerList(payload).then((res) => {
			this.dataFetched = true;
      this.popularSearch = res.dataset;
    }, (error) => {

    })
    
  },
  mounted() {
    this.searchDatabase = [];
    this.getIntersectionObserver();
  },
  watch: {
    getSearchdata (val) {
      if (!val) return;
    }
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setRtl",
      "setSubscriptionList",
      "setSearchdataset",
      "setSubscriptionInformation"
    ]),
    initialRoute() {
      this.$router.push({ name: "Home" });
    },

    // Debounce function
    debounce(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    debouncedSearch() {
      this.pageNum = 1;
      this.searchDatabase = [];
      this.setSearchdataset([]);
      this.showLoader = false;
      this.categories = [];
      this.totalCount = 0;
      this.dataFetched = false;
      if (this.query?.length >= 3) {
        this.$router.push({path:'search',query:{q:this.query}});
        this.searchApi(this.query);
      } else {
        this.searchDatabase = [];
        this.dataFetched = true;
        this.setSearchdataset([]);
        if(this.query == '') {
          // let currentLanguage = this.getCurrentLanguageUrlBase();
          this.$router.push({path:'search'});
        } else {
          this.$router.push({path:'search', query:{q:this.query}});
        }
      }
    },
    // Search function
    searchApi(query) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
      let payload = {
        query: query,
        displaylanguage: this.localDisplayLang,
        pagesize: 15,
        page: this.pageNum
      }
      if(query === "") return;
      actGetSearchData(payload).then((res) => {
          this.handleSearchedData(res);
        },
        (error) => {
          this.setSearchdataset([]);
          this.dataFetched = true;
        });
    },
    handleSearchedData(data) {
      if(data && this.query === "") return;
      this.searchDatabase = data?.dataset ? [...this.searchDatabase, ...data.dataset] : [];
      if(this.searchDatabase) {
        if(!this.categories?.includes('ALL')) this.categories.push('ALL');
        this.moviesDatabase = this.searchDatabase?.filter((data) => {
          return data.category == "MOVIE";
        })
        this.tvshowsDatabase = this.searchDatabase?.filter((data) => {
          return data.category == "TVSHOW";
        })
        this.livetvDatabase = this.searchDatabase?.filter((data) => {
          return data.category === "TVCHANEL";
        });
        if(this.moviesDatabase?.length > 0 && !this.categories?.includes('MOVIES'))  this.categories?.push('MOVIES');
        if(this.tvshowsDatabase?.length > 0 && !this.categories.includes('TVSHOWS'))  this.categories?.push('TVSHOWS');
        if(this.livetvDatabase?.length > 0 && !this.categories.includes('TVCHANEL'))  this.categories?.push('TVCHANEL');
      }
      this.totalCount = data.totalcount;
      if(this.totalCount <= this.searchDatabase?.length) {
        this.isScrollable = false;
        this.showLoader = false;
      } else {
        this.isScrollable = true;
        this.showLoader = true;
      }
      this.dataFetched = true;
    },
    clearSearch() {
      this.query = '';
      this.searchDatabase = [];
      this.setSearchdataset([]);
      this.$router.push({path:'search'});
    },
    selectCategory(item) {
      // this.dataFetched = false;
      this.selectedCategory = item;
      // setTimeout(()=>{this.dataFetched = true}, 100)
    },
    getIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.55
      };
      let self = this;
      var observer = new IntersectionObserver(this.handleIntersect, options);
      let idScroll = document.getElementById('IntersectContainer');
      observer.observe(idScroll);
    },
    handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (this.totalCount > this.searchDatabase?.length) {
            this.pageNum += 1;
            this.searchApi(this.query);
          }
        }
      });
    }
  },
  beforeDestroy() {
    eventBus.$emit("search-close", true);
    eventBus.$emit("enable_header");
      },
  components: {
    detailPageLoader: () => import(/* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"),
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
    SearchSlider: () => import(/* webpackChunkName: "SearchSlider" */ "./SearchSlider.vue"),
    GridHoverCard: () => import(/* webpackChunkName: "gridHoverCardHover" */ "@/themeearth/components/HoverCards/GridHoverCard.vue"),
    EptyBin: () => import(/* webpackChunkName: "eptyBin" */ "@/components/SvgImages/Empty.vue"),
    Grid: () => import(/* webpackChunkName: "Grid" */ "@/components/Templates/GridLayout.vue"),
    GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins:['Utility']
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./SearchPage.scss";
.search {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  #search-box {
    display: flex;
    align-items: center;
    background-color: #1d1d1d;
    border: 1px solid #00000000;
    border-radius: 8px;
    box-sizing: border-box;
    min-width: min-content;
    padding: 0px 12px;
    transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, fill 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, stroke 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, filter 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, backdrop-filter 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, -webkit-backdrop-filter 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    width: clamp(2rem, 100%, 95%);
    margin: 0 auto;
    .serch-icon {

    }
    input {
      width: 100%;
      padding: 0.8rem 1.8rem 0.8rem 1.2rem;
      border: none;
      font-size: 1.2rem;
      font-weight: 500;
      color: #e1e6f0;
      outline: none;
    }
    .clear-btn {
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .category-nav {
    width: clamp(2rem, 100%, 95%);
    margin: 0 auto;
    .list-items {
      width: 100%;
      display: flex;
      align-items: center;
      width: auto;
      overflow-x: auto;
      gap: 0.5rem;
      // padding: 0.4rem 0.4rem;
      li {  
          padding: 0.4rem 0.2rem;
          cursor: pointer;
          transform: scale(1.1, 1.2);
          p{
            font-weight: 500;
            font-size: 0.9rem !important;
            background: #1f1e1e;
            padding: 0.3rem 0.5rem;
            border-radius: 0.2rem;
            span {
              text-wrap: nowrap;
            }
          }
      }      
      .active {
        align-items: center;
        p{
          color: #FF0000;
          background-color: #e1e6f0;
          // border: 1px solid #FF0000;
        }
      }
    }
  }
  .search-container{
    width: clamp(2rem, 100%, 95%);
    margin: auto;
    .result-box {
      .inner-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
    .EmptyContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
    .card-container {
      position: relative;
      width: 100%;
      height: 100%;
      .hover-grid-card{
        display: none;
      }
      &:hover{  
        .hover-grid-card{
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 999;
          transform: translate(-50%, -50%);
        }
      }
    }  
  }
}
@media screen and (max-width: 1023px) {
  .search {
    margin-top: 8rem;
  }
}
</style>
